/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const About = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/speaking-hero.png/" }) {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            twitter
          }
        }
      }
    }
  `)
  return (
    <>
      <div
        style={{
          display: `flex`,
        }}
      >
        <img className="hero" src={data.avatar.childImageSharp.fluid.src} />
      </div>
      <div style={{ marginTop: '2rem' }}>
        <p>
          I live in Raleigh, NC and work on the web building products that solve real business problems.
        </p>
        <h2>What I'm Working On</h2>
        <ol>
          <li>👨🏻‍💻 I'm a software engineer pursuing <Link to="/work">self-employment</Link> through a portfolio of business activities.</li>
          <li>📬 I write a weekly newsletter, Elbaum's World, which comes out on Sundays. The newsletter covers code, personal finance and self improvement. Check out the <Link to="/newsletter">past issues</Link> and <Link to="/subscribe">subscribe</Link> if you like what you find.</li>
          <li>📹 I have a <a href="https://www.youtube.com/channel/UCPcyffOFc7YGyDgqmvZJs7Q" target="_blank">YouTube channel</a> that I upload videos to weekly(ish). I'm still trying to figure out my focus there, but the topics tend to be around software engineering career development.</li>
          <li>🐦 I <a href="https://twitter.com/peterelbaum" target="_blank">tweet</a> summary notes from articles and podcasts, and my thoughts about developer career growth.</li>
          <li>🎤 I speak at meetups and conferences semi-regularly, on technical and soft-skills topics.</li>
        </ol>

        <h2>Fun Facts</h2>
        <ol>
          <li>🤓 I learn in public. This means that I try to document everything I'm learning, thinking and doing with the express purpose of providing value to others.</li>
          <li>🇪🇸 I studied Spanish at university and studied abroad for 5 months in Madrid. Additional fun fact: I was <a href="https://www.youtube.com/watch?v=IP2cP6uvTxA&t=1m26s" target="_blank">technically homeless</a> for my last ~6 weeks there.</li>
          <li>👫 I'm a twin! This is usually my go-to fun fact in icebreaker situations. I think being a twin is special and privileged experience.</li>
        </ol>

        <h2>Curated Lists</h2>
        <p>I curate lists of resources on topics I find interesting/helpful:</p>
        <ul>
          <li><Link to="/learn-in-public">Learn in Public</Link></li>
          <li><Link to="/salary-negotiation">Salary Negotiation</Link></li>
        </ul>

        <h2>Who I Follow</h2>
        <ul>
          <li><a href="https://aliabdaal.com" target="_blank">Ali Abdaal</a></li>
          <li><a href="hhttps://stephsmith.io/" target="_blank">Steph Smith</a></li>
          <li><a href="https://tim.blog/" target="_blank">Tim Ferriss</a></li>
          <li><a href="https://fs.blog/" target="_blank">Shane Parrish</a></li>
          <li><a href="https://www.indiehackers.com/" target="_blank">Courtland Allen</a></li>
          <li><a href="https://www.kalzumeus.com/" target="_blank">Patrick McKenzie</a></li>
          <li><a href="https://marketingexamples.com/" target="_blank">Harry Dry</a></li>
          <li><a href="https://joelhooks.com/" target="_blank">Joel Hooks</a></li>
          <li><a href="https://www.swyx.io/" target="_blank">Shawn Wang</a></li>
          <li><a href="https://kentcdodds.com/" target="_blank">Kent C. Dodds</a></li>
          <li><a href="https://wesbos.com/" target="_blank">Wes Bos</a></li>
          <li><a href="https://www.scotttolinski.com/" target="_blank">Scott Tolinski</a></li>
        </ul>
        
        <h2>Standing Coffee + Office Hours ☕️</h2>
        <p>If you want to talk tech, self improvement, or anything else, I'm down. If you find yourself in Raleigh and want to hang out, just let me know. I'll buy you a coffee and we can chat.</p> 
        <p>Alternatively, if you're not in Raleigh but still want to chat, please feel free to schedule a slot in my <a rel="noreferrer" href="https://calendly.com/elbaumpj/saturday-office-hours" target="_blank">office hours</a>!</p>
      </div>
    </>
  )
}

export default About
